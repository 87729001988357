function trackAddToBag({
  productId, price, imageUrl, name, url,
}) {
  if (!window.geq) { return; }

  window.geq.addToCart({
    productId,
    price,
    quantity: 1,
    imageUrl,
    name,
    url,
  });
}

const retentionPixel = {
  trackAddToBag,
};

export default retentionPixel;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Tooltip.module.sass';

const cx = classNames.bind(styles);

/** A tooltip container component for rendering validation/information text. */
const Tooltip = React.forwardRef((props, ref) => {
  const {
    type,
    className,
    children,
  } = props;

  return (
    <div className={cx(styles.tooltip, className)} ref={ref}>
      <div className={cx(styles.children, styles[type])}>{ children }</div>
      <span className={cx(styles.arrow, styles[type])} />
    </div>
  );
});

Tooltip.propTypes = {
  /** Content of the tooltip . */
  children: PropTypes.node.isRequired,
  /** To indicate the overall visual styling of a tooltip. */
  type: PropTypes.oneOf(['error', 'info']).isRequired,
  /** Any CSS classes to apply to component. */
  className: PropTypes.string,
};

export default Tooltip;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '../../../../ui_kit/input/Input';
import Button from '../../../../ui_kit/button/Button';
import styles from './JoinWaitlist.module.sass';
import { email as emailIsInvalid, isEmpty } from '../../../../services/validations';
import { userObjectSelector } from '../../../../user/selectors/user';

class JoinWaitlist extends Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      email: user ? user.email : '',
      error: '',
    };
  }

  onInputChange = (event) => {
    this.setState({
      email: event.target.value,
      error: '',
    });
  };

  subscribe = () => {
    const { email } = this.state;

    if (isEmpty(email)) {
      this.setState({ error: "Email can't be empty" });
      return;
    }
    if (emailIsInvalid(email)) {
      this.setState({ error: 'Email is invalid' });
      return;
    }

    const { productId, subscribe } = this.props;
    subscribe({ email, productId });
  };

  render() {
    const { email, error } = this.state;
    const { loading, estimatedShipDate, buttonText = 'JOIN WAITLIST' } = this.props;

    return (
      <div className={styles['join-waitlist']}>
        { !estimatedShipDate && (
          <div className={styles.description}>
            Coming soon
          </div>
        )}
        <form
          className={styles.form}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <Input
            value={email}
            onChange={this.onInputChange}
            placeholder="Your email"
            className={styles.input}
            error={error}
            type="email"
          />
          <Button
            text={buttonText}
            size="small"
            color="blue"
            loading={loading}
            type="submit"
            className={styles.button}
            onClickHandler={this.subscribe}
          />
        </form>
      </div>
    );
  }
}

JoinWaitlist.propTypes = {
  productId: PropTypes.number.isRequired,
  subscribe: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  estimatedShipDate: PropTypes.string,
  buttonText: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  user: userObjectSelector(state),
});

export default connect(mapStateToProps)(JoinWaitlist);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Input.module.sass';
import Tooltip from '../tooltip/Tooltip';
import WithInfoTooltip from '../with_info_tooltip/WithInfoTooltip';

const cx = classNames.bind(styles);

/** A simple input component with option to render placeholder as label. */
class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      active: false,
    };
  }

  onChange = (event) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }
  };

  onFocus = (event) => {
    const { onFocus } = this.props;
    this.setState({ focused: true });
    if (onFocus) {
      onFocus(event);
    }
  };

  onBlur = (event) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) {
      onBlur(event);
    }
  };

  onMouseDown = (event) => {
    const { onMouseDown } = this.props;
    this.setState({ active: true });
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  onMouseUp = (event) => {
    const { onMouseUp } = this.props;
    this.setState({ active: false });
    if (onMouseUp) {
      onMouseUp(event);
    }
  };

  iconOnClickHandler = (event) => {
    const { iconOnClickHandler, toggleHint } = this.props;
    toggleHint();

    if (iconOnClickHandler) {
      iconOnClickHandler(event);
    }
  };

  render() {
    const {
      className,
      errorTooltipClassName,
      infoTooltipClassName,
      label,
      type = 'text',
      id,
      tabIndex = 0,
      name,
      value,
      placeholder,
      description,
      disabled,
      error,
      hint,
      icon,
      hintShown,
      inputRef,
      iconRef,
      tooltipRef,
      autocomplete,
      prefix,
    } = this.props;
    const {
      focused,
      active,
    } = this.state;
    const inputCx = {
      disabled,
      focused,
      active,
      error,
    };

    return (
      <div className={cx(styles.inputContainer, className)}>
        { error
          && focused
          && (
            <Tooltip className={cx(errorTooltipClassName, styles.tooltipMargin, { descriptionTooltipMargin: description })} type="error">
              <div>{ error }</div>
            </Tooltip>
          )}
        {
          hintShown
          && hint
          && (
            <Tooltip className={cx(infoTooltipClassName, styles.tooltipMargin, { descriptionTooltipMargin: description })} type="info" ref={tooltipRef}>
              <div>{ hint }</div>
            </Tooltip>
          )
        }
        { label }
        <label htmlFor={id} className={cx(styles.input, inputCx)} data-prefix={prefix}>
          <input
            ref={inputRef}
            tabIndex={tabIndex}
            type={type}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            disabled={disabled}
            autoComplete={autocomplete}
          />
          {
            icon
            && (
              <button tabIndex="-1" type="button" ref={iconRef} onClick={this.iconOnClickHandler}>{ icon }</button>
            )
          }
        </label>
        {
          description && (<p className={styles.description}>{ description }</p>)
        }
      </div>
    );
  }
}

Input.propTypes = {
  /** Any CSS classes to apply to component. */
  className: PropTypes.string,
  /** Any CSS classes to apply to component error tooltip. */
  errorTooltipClassName: PropTypes.string,
  /** Any CSS classes to apply to component info tooltip. */
  infoTooltipClassName: PropTypes.string,
  /** Label for input. */
  label: PropTypes.string,
  /** Specifies the type of input (default is text). */
  type: PropTypes.string,
  /** Id for input. */
  id: PropTypes.string,
  /** Tab index for component's input tag. */
  tabIndex: PropTypes.number,
  /** Name for input. */
  name: PropTypes.string,
  /** Default value for input. */
  value: PropTypes.string,
  /** Placeholder for input. */
  placeholder: PropTypes.string,
  /** Description for input */
  description: PropTypes.string,
  /** Error text to show in tooltip above input. */
  error: PropTypes.string,
  /** Hint text to show in tooltip above input. */
  hint: PropTypes.node,
  /** Boolean indicationg whether to show hint tooltip. */
  hintShown: PropTypes.bool,
  /** Handler to toggle hint. */
  toggleHint: PropTypes.func,
  /** Clickable icon to render within input container, used to toggle hint. */
  icon: PropTypes.node,
  /** Boolean indicating whether input should render as disabled. */
  disabled: PropTypes.bool,
  /** onChange handler. */
  onChange: PropTypes.func,
  /** onFocus handler. */
  onFocus: PropTypes.func,
  /** onBlur handler. */
  onBlur: PropTypes.func,
  /** onMouseDown handler. */
  onMouseDown: PropTypes.func,
  /** onMouseUp handler. */
  onMouseUp: PropTypes.func,
  /** Handler for icon. */
  iconOnClickHandler: PropTypes.func,
  /** Ref for icon. */
  inputRef: PropTypes.shape({}),
  /** Ref for icon. */
  iconRef: PropTypes.shape({}),
  /** Ref for tooltip. */
  tooltipRef: PropTypes.shape({}),
  /** Autocomplete for input. */
  autocomplete: PropTypes.string,
  /** Prefix for input */
  prefix: PropTypes.string,
};

/** Wrapper for Input component to support hiding info tooltip when clicking outside. */
const InputWithInfoTooltip = React.forwardRef((props, ref) => (
  <WithInfoTooltip>
    <Input {...props} inputRef={ref} />
  </WithInfoTooltip>
));

InputWithInfoTooltip.propTypes = Input.propTypes;

export default InputWithInfoTooltip;

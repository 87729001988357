export const isEmpty = (value) => (!value || !/\S/.test(value));

const EMAIL_REGEX = /^([0-9a-zA-Z]+[-._])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/;
const USA_PHONE_NUMBER_REGEX = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;
const POSTAL_CODE_REGEX = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;

export const emailIsValid = (value) => !(value && !EMAIL_REGEX.test(value));

export const email = (value) => (value && !EMAIL_REGEX.test(value)
  ? 'Invalid email address' : undefined);

export const zipcode = (value) => POSTAL_CODE_REGEX.test(value);

export const phone = (value) => USA_PHONE_NUMBER_REGEX.test(value);

const shippingInfoForm = (values) => {
  const errors = {};
  if (isEmpty(values.firstname)) {
    errors.firstname = 'First name is required';
  }
  if (isEmpty(values.lastname)) {
    errors.lastname = 'Last name is required';
  }
  if (isEmpty(values.email)) {
    errors.email = 'Email is required';
  } else if (email(values.email)) {
    errors.email = email(values.email);
  }
  if (isEmpty(values.city)) {
    errors.city = 'City is required';
  }
  if (isEmpty(values.zipcode)) {
    errors.zipcode = 'Zip is required';
  } else if (!zipcode(values.zipcode)) {
    errors.zipcode = 'Zip is invalid';
  }
  if (isEmpty(values.address1)) {
    errors.address1 = 'Address is required';
  }
  if (!isEmpty(values.phone) && !phone(values.phone)) {
    errors.phone = 'Phone is invalid';
  }
  return errors;
};

const creditCardForm = (values) => {
  const errors = {};

  if (isEmpty(values.name)) {
    errors.name = 'Cardholder name is required';
  }

  if (isEmpty(values.zip)) {
    errors.zip = 'Zip is required';
  } else if (!zipcode(values.zip)) {
    errors.zip = 'Zip is invalid';
  }

  return errors;
};

const signInForm = (values) => {
  const errors = {};
  if (isEmpty(values.email)) {
    errors.email = 'Email is required';
  } else if (email(values.email)) {
    errors.email = email(values.email);
  }
  if (isEmpty(values.password)) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password is too short (minimum is 8 characters)';
  }
  return errors;
};

const emailForm = (values) => {
  const errors = {};
  if (isEmpty(values.email)) {
    errors.email = 'Email is required';
  } else if (email(values.email)) {
    errors.email = email(values.email);
  }
  return errors;
};

const submitReviewForm = (values) => {
  const errors = {};
  if (isEmpty(values.username)) {
    errors.username = 'Name is required';
  }
  if (isEmpty(values.email)) {
    errors.email = 'Email is required';
  } else if (email(values.email)) {
    errors.email = email(values.email);
  }
  if (isEmpty(values.title)) {
    errors.title = 'Opinion is required';
  }
  if (isEmpty(values.content)) {
    errors.content = 'Review is required';
  }
  if (isEmpty(values.rating)) {
    errors.content = 'Rating is required';
  }
  return errors;
};

const accountInformationForm = (values) => {
  const errors = {};

  if (!isEmpty(values.phone) && !phone(values.phone)) {
    errors.phone = 'Phone is invalid';
  }
  return errors;
};

const addressForm = (values) => {
  const errors = {};

  if (isEmpty(values.city)) {
    errors.city = 'City is required';
  }
  if (isEmpty(values.zipcode)) {
    errors.zipcode = 'Zip is required';
  } else if (!zipcode(values.zipcode)) {
    errors.zipcode = 'Zip is invalid';
  }
  if (isEmpty(values.address1)) {
    errors.address1 = 'Address is required';
  }
  return errors;
};

const passwordForm = (values) => {
  const errors = {};

  if (isEmpty(values.current_password)) {
    errors.current_password = 'Current password is required';
  }
  if (isEmpty(values.password)) {
    errors.password = 'New password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password is too short (minimum is 8 characters)';
  }
  if (isEmpty(values.password_confirmation)) {
    errors.password_confirmation = 'Password confirmation is required';
  } else if (values.password_confirmation.length < 8) {
    errors.password_confirmation = 'Password confirmation is too short (minimum is 8 characters)';
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Passwords don\'t match';
  }
  return errors;
};

const aboutForm = (values) => {
  const errors = {};

  if (isEmpty(values.firstName)) {
    errors.firstName = 'First name is required';
  }
  if (isEmpty(values.lastName)) {
    errors.lastName = 'Last name is required';
  }
  if (isEmpty(values.state)) {
    errors.state = 'State is required';
  }
  if (!isEmpty(values.age) && (Number(values.age) > 120 || Number(values.age) < 5)) {
    errors.age = 'Age is invalid';
  }
  if (isEmpty(values.solvingProblems)) {
    errors.solvingProblems = 'Solving problems are required';
  }

  if (isEmpty(values.inspiration)) {
    errors.inspiration = 'This field is required';
  }

  return errors;
};

const extendedSignUpForm = (values) => {
  const errors = {};

  if (isEmpty(values.firstName)) {
    errors.firstName = 'First name is required';
  }
  if (isEmpty(values.lastName)) {
    errors.lastName = 'Last name is required';
  }
  if (isEmpty(values.email)) {
    errors.email = 'Email is required';
  } else if (email(values.email)) {
    errors.email = email(values.email);
  }
  if (isEmpty(values.password)) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password is too short (minimum is 8 characters)';
  }

  if (isEmpty(values.birthMonth)) {
    errors.birthMonth = 'Birthday month is required';
  }
  if (isEmpty(values.birthDay)) {
    errors.birthDay = 'Birthday Date is required';
  }

  return errors;
};

const validations = {
  shippingInfoForm,
  creditCardForm,
  isEmpty,
  signInForm,
  emailForm,
  submitReviewForm,
  accountInformationForm,
  addressForm,
  passwordForm,
  aboutForm,
  extendedSignUpForm,
};

export default validations;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WithInfoTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipRef: React.createRef(),
      iconRef: React.createRef(),
      hintShown: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClickHandler);
    window.addEventListener('blur', this.onWindowBlurHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClickHandler);
    window.removeEventListener('blur', this.onWindowBlurHandler);
  }

  onWindowClickHandler = (event) => {
    const { hintShown, tooltipRef, iconRef } = this.state;
    if (hintShown
        && tooltipRef.current
        && iconRef.current
        && !tooltipRef.current.contains(event.target)
        && !iconRef.current.contains(event.target)) {
      this.setState({
        hintShown: false,
      });
    }
  };

  onWindowBlurHandler = () => {
    if (document.activeElement.tagName === 'IFRAME') {
      this.setState({
        hintShown: false,
      });
    }
  };

  toggleHint = () => {
    this.setState((prevState) => ({
      hintShown: !prevState.hintShown,
    }));
  };

  render() {
    const { tooltipRef, iconRef, hintShown } = this.state;
    const { children } = this.props;

    const clonedChildren = React.Children.map(children, (child) => React.cloneElement(child, {
      tooltipRef,
      iconRef,
      hintShown,
      toggleHint: this.toggleHint,
    }));

    return clonedChildren;
  }
}

WithInfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithInfoTooltip;

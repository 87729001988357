import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../../ui_kit/button/Button';
import googleAnalytics from '../../../services/google_analytics';
import facebookPixel from '../../../services/facebook_pixel';
import JoinWaitlist from './join_waitlist/JoinWaitlist';
import styles from './ProductButton.module.sass';
import retentionPixel from '../../../services/retention_pixel';

class ProductButton extends Component {
  trackAddToBag = () => {
    const {
      productId, price, variant, name, sku, productUrl, image,
    } = this.props;
    const product = {
      sku, variant, name, price, productUrl,
    };

    facebookPixel.trackAddToBag(productId, price);
    googleAnalytics.trackAddToBag(product);
    retentionPixel.trackAddToBag({
      productId, price, name, url: window.location.origin + productUrl, imageUrl: image,
    });
  };

  addToBag = () => {
    const { addToBagClickHandler } = this.props;

    this.trackAddToBag();

    addToBagClickHandler();
  };

  render() {
    const {
      waitlistSubscribable, subscribed, available,
      redirectionEnabled, redirectText, limitation, showAddToBag,
      redirectLink, productId, showPriceOnButton,
      showSubscription, subscribe, estimatedShipDate,
      buttonColor = 'blue', productUrl, loading, price, discount = 0, addToBagText: addToBagTextFromBackend,
    } = this.props;

    const addToBagText = addToBagTextFromBackend || 'ADD TO BAG';

    return (
      <>
        { waitlistSubscribable && subscribed && (
          <div className={styles.subscribed}>Waitlisted</div>
        )}
        { waitlistSubscribable && !subscribed && !showSubscription && (
          <Button
            text="JOIN WAITLIST"
            size="small"
            color={buttonColor}
            className="button"
            href={productUrl}
          />
        )}
        { waitlistSubscribable && !subscribed && showSubscription && subscribe && (
          <JoinWaitlist
            productId={productId}
            subscribe={subscribe}
            loading={loading}
            estimatedShipDate={estimatedShipDate}
          />
        )}
        { !waitlistSubscribable && !available && (
        <p>
          This item is limited to
          {' '}
          {limitation}
          {' '}
          per customer
        </p>
        ) }
        { !waitlistSubscribable && available && redirectionEnabled && (
          <Button
            text={redirectText}
            size="small"
            color={buttonColor}
            className="button"
            href={redirectLink.length > 0 ? redirectLink : productUrl}
          />
        ) }
        { !waitlistSubscribable && available && !redirectionEnabled && (
          showAddToBag ? (
            <Button
              text={showPriceOnButton ? `${addToBagText} $${price - discount}` : addToBagText}
              size="small"
              color={buttonColor}
              className="button"
              loading={loading}
              onClickHandler={this.addToBag}
            />
          ) : (
            <Button
              text="CHOOSE"
              size="small"
              color={buttonColor}
              className="button"
              loading={loading}
              href={productUrl}
            />
          )
        ) }
      </>
    );
  }
}

ProductButton.propTypes = {
  addToBagText: PropTypes.string,
  waitlistSubscribable: PropTypes.bool.isRequired,
  subscribed: PropTypes.bool,
  available: PropTypes.bool.isRequired,
  redirectionEnabled: PropTypes.bool.isRequired,
  redirectText: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  limitation: PropTypes.number,
  showAddToBag: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  name: PropTypes.string.isRequired,
  showSubscription: PropTypes.bool,
  variant: PropTypes.string,
  sku: PropTypes.string.isRequired,
  subscribe: PropTypes.func,
  productId: PropTypes.number.isRequired,
  estimatedShipDate: PropTypes.string,
  loading: PropTypes.bool,
  buttonColor: PropTypes.oneOf([
    'black', 'white', 'blue', 'transparent-blue', 'yellow', 'green', 'transparent-green',
  ]),
  productUrl: PropTypes.string,
  addToBagClickHandler: PropTypes.func.isRequired,
  showPriceOnButton: PropTypes.bool,
  image: PropTypes.string,
};

export default ProductButton;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Button.module.sass';

function Button(props) {
  const {
    disabled, onClickHandler, loading, text, color = 'black',
    id, className, icon, size = 'normal', style, type = 'button', children,
    href, target,
  } = props;

  const content = (
    <>
      { loading && <div className={styles.loader} /> }
      { !loading && (
        <>
          { icon && <div className={styles.icon}>{icon}</div> }
          {text || children}
        </>
      )}
    </>
  );

  if (href) {
    return (
      <a
        id={id}
        href={href}
        style={style}
        target={target}
        className={cx(
          styles.button,
          styles[color],
          styles[size],
          loading ? styles.loading : '',
          className,
        )}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      style={style}
      className={cx(
        styles.button,
        styles[color],
        styles[size],
        loading ? styles.loading : '',
        className,
      )}
      disabled={disabled}
      onClick={onClickHandler}
    >
      {content}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'black', 'white', 'blue', 'transparent-blue', 'yellow',
    'green', 'transparent-green', 'transparent-white', 'rose',
  ]),
  size: PropTypes.oneOf(['normal', 'small']),
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string,
  loading: PropTypes.bool,
  onClickHandler: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  type: PropTypes.oneOf(['button', 'submit']),
  href: PropTypes.string,
  target: PropTypes.oneOf(['_blank', null]),
};

export default Button;

function addProduct(item, quantity) {
  if (!window.ga) { return; }
  window.ga('ec:addProduct', {
    id: item.sku,
    name: item.name,
    brand: 'Volition Beauty',
    variant: item.variant,
    price: item.price,
    quantity,
  });
}

function addGoogleAnalyticsProducts(lineItems) {
  if (!window.ga || !lineItems || lineItems.length === 0) { return; }
  window.ga('require', 'ec');
  lineItems.forEach((item) => {
    addProduct(item, item.quantity);
  });
}

function trackAddToBag(product, quantity = 1) {
  if (!window.ga || !product) { return; }

  window.ga('require', 'ec');
  addProduct(product, quantity);
  window.ga('ec:setAction', 'add');
  window.ga('send', 'pageview');
}

function trackRemoveFromBag(lineItem, quantity = 1) {
  if (!window.ga || !lineItem) { return; }
  window.ga('require', 'ec');
  addProduct(lineItem, quantity);
  window.ga('ec:setAction', 'remove');
  window.ga('send', 'pageview');
}

function trackCheckout(step, option) {
  if (!window.ga) { return; }
  window.ga('ec:setAction', 'checkout', {
    step,
    option,
  });
  window.ga('send', 'pageview');
}

function trackCheckoutOption(step, option) {
  if (!window.ga) { return; }
  window.ga('ec:setAction', 'checkout_option', {
    step,
    option,
  });
  window.ga('send', 'pageview');
}

const googleAnalytics = {
  addProduct,
  addGoogleAnalyticsProducts,
  trackAddToBag,
  trackRemoveFromBag,
  trackCheckout,
  trackCheckoutOption,
};

export default googleAnalytics;
